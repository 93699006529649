import { CardCenteredInterface } from "@life-without-barriers/react-components"

export const LearnAboutFosterCare: CardCenteredInterface = {
  title: "About foster care",
  showReadMore: true,
  href: "/foster-care/about-foster-care/",
  copy: "For children who need time away from their family, foster care provides a safe and supportive home where they can heal and grow.",
  icon: "family",
  actionLabel: "Read more"
}

export const CanIBeACarer: CardCenteredInterface = {
  title: "Can I be a carer?",
  showReadMore: true,
  href: "/foster-care/can-i-be-a-carer/",
  copy: "It's likely that you can, our foster carers come from all walks of life. To become a foster carer your ability to care for and nurture a child is what really matters.",
  icon: "clipboard",
  actionLabel: "Read more"
}

export const TypesOfFosterCare: CardCenteredInterface = {
  title: "Types of foster care",
  showReadMore: true,
  href: "/foster-care/types-of-foster-care",
  copy: "When it comes to fostering, no two children or families are the same. That is why we offer a range of placement types to meet the unique needs of children in care.",
  icon: "kinship",
  actionLabel: "Read more"
}

export const CarerGuide: CardCenteredInterface = {
  title: "Carer Guide",
  showReadMore: true,
  href: "/foster-care/support/carer-guide",
  copy: "Your resource centre throughout your carer journey.",
  icon: "education",
  actionLabel: "Find out more"
}

export const CarerLearningPathways: CardCenteredInterface = {
  title: "Carer Learning Pathways",
  showReadMore: true,
  href: "/foster-care/caring-space/carer-learning-pathways/",
  copy: "Learning that suits your lifestyle.",
  actionLabel: "Find out more",
  icon: "schooling"
}

export const NationalCarerConferenceNoIcon: CardCenteredInterface = {
  title: "LWB Annual Carer Conference",
  showReadMore: true,
  href: "/foster-care/caring-space/national-carer-conference/",
  copy: "Collaboration, Connection, Community.",
  actionLabel: "Learn more"
}

export const CarerEvents: CardCenteredInterface = {
  title: "Carer Events",
  showReadMore: true,
  href: "/foster-care/caring-space/events/",
  rooted: true,
  icon: "event",
  copy: "See our upcoming foster and kinship care events",
  actionLabel: "Find out more"
}

export const NationalCarerConference: CardCenteredInterface = {
  ...NationalCarerConferenceNoIcon,
  icon: "event",
  actionLabel: "Find out more"
}

export const TheCaringDays: CardCenteredInterface = {
  title: "The Caring Days",
  showReadMore: true,
  href: "/foster-care/caring-space/the-caring-days/",
  copy: "Connect with other Carer families right now on Facebook!",
  actionLabel: "Find out more",
  icon: "care"
}

export const CarerBenefitsNoIcon: CardCenteredInterface = {
  title: "Carer Benefits",
  showReadMore: true,
  href: "/foster-care/caring-space/carer-benefits/",
  copy: "Carer perks – just for you!"
}

export const CarerBenefits: CardCenteredInterface = {
  ...CarerBenefitsNoIcon,
  icon: "add",
  actionLabel: "Find out more"
}

export const GetInTouchNoIcon: CardCenteredInterface = {
  title: "Get in touch",
  showReadMore: true,
  href: "/foster-care/caring-space/get-in-touch/",
  copy: "We’re here to answer your questions."
}

export const GetInTouch: CardCenteredInterface = {
  ...GetInTouchNoIcon,
  icon: "chat",
  actionLabel: "Find out more"
}

export const ShortAndMedium: CardCenteredInterface = {
  title: "Short-term foster care",
  showReadMore: true,
  href: "/foster-care/types-of-foster-care/short-term-foster-care/",
  icon: "shortMedium"
}

export const EmergencyOrCrisis: CardCenteredInterface = {
  title: "Emergency or Crisis care",
  showReadMore: true,
  href: "/foster-care/types-of-foster-care/emergency-foster-care/",
  icon: "emergency",
  iconHeight: "50"
}

export const AllTypesOfFosterCare: CardCenteredInterface = {
  title: "Other types of foster care",
  showReadMore: true,
  href: "/foster-care/about-foster-care/",
  icon: "allTypes",
  iconHeight: "70"
}
