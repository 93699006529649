import { graphql, Link } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import {
  Banner,
  Helmet,
  Theme,
  StatsCard,
  ReadMoreLink,
  NavigationTiles,
  IconKinship,
  IconEmergency,
  IconShortTerm,
  ContactUsPhoneLink,
  ContactUsEmailLink,
  SocialMediaLinks,
  PromoPanelRightImage,
  Variables,
  Section,
  Container,
  Row,
  Box,
  Heading,
  routesObject,
  StandardButton
} from "@life-without-barriers/react-components"

import {
  ShortAndMedium,
  EmergencyOrCrisis,
  AllTypesOfFosterCare
} from "../../../components/foster-care/RelatedPagesContent"
import Layout from "../../../components/foster-care/Layout"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import { Site } from "../../../contentTypes"

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    storyRaechel: IGatsbyImageData
  }
}
const { fosterCareThemeFull } = Theme
const { youthPinkXXXLight } = Variables

const supportsList = [
  {
    header: "Training",
    text: "Life Without Barriers will provide you with a range of learning opportunities suited to your level of experience and the needs of the children in your care."
  },
  {
    header: "24/7 Carer Support",
    text: "Our carers have access to support at any time of the day or night for advice, support and direction in stressful situations"
  },
  {
    header: "Financial support",
    text: "Foster carers receive a tax-free allowance to support the needs of the children placed in their care. This differs according to the needs of the child/children and where you live."
  },
  {
    header: "Specialist support",
    text: "Life Without Barriers has specialist staff who will work alongside you, and the children in your care, when additional support is needed."
  },
  {
    header: "Carer networking",
    text: "Talking to other carers who are just like you is sometimes the best form of support. Through regular events, we create opportunities for you to share your concerns, celebrate successes and provide feedback to the Life Without Barriers team and other carers in your network."
  }
]

const iconSelector = (
  type: string,
  color: string,
  height?: string,
  width?: string
): JSX.Element | undefined => {
  if (type === "allTypes") {
    return <IconKinship color={color} height={height} width={width} />
  }
  if (type === "emergency") {
    return <IconEmergency color={color} height={height} width={width} />
  }
  if (type === "shortMedium") {
    return <IconShortTerm color={color} height={height} width={width} />
  }
  return undefined
}

const RespiteCarePage = ({
  location,
  data: {
    storyRaechel,
    site: { siteMetadata }
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`How to become a respite foster carer | ${siteMetadata.title}`}
        description={
          "What is respite foster care? It is short-break support for not only children in need but also their foster family or parents. Offer stability and support to a child today."
        }
        image={storyRaechel}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout>
        <Banner
          title="Respite foster care"
          breadcrumbs={[
            routesObject.fosterCareHome,
            {
              to: "/foster-care/about-foster-care",
              text: "About foster care",
              title: "About foster care"
            },
            {
              to: "/foster-care/types-of-foster-care",
              text: "Types of foster care",
              title: "Types of foster care navigation"
            }
          ]}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>What is respite foster care?</Heading>
              <p>
                As a respite foster carer you will provide a caring and
                supportive role, not only to children in need, but also to their
                full-time foster family. Although you may only dedicate a small
                amount of time per month to as a respite foster carer, you will
                be making an enormous impact by offering stability and respect
                to some of Australia’s 50,000 children who cannot live safely at
                home.
              </p>
            </Box>
          </Row>
        </Container>
        <PromoPanelRightImage image={storyRaechel} color={youthPinkXXXLight}>
          <div>
            <blockquote className="mh0 mv1 fw2 ts-display-3">
              &quot;If full-time foster care isn&apos;t for you, respite caring
              is most definitely an option.&quot;
            </blockquote>
            <p className="w-100 mb2 mb4-l ttu x-small">
              Raechel, Life Without Barriers respite foster carer
            </p>
            <div className="pt3">
              <Link
                className="link normal pr3"
                to="/foster-care/stories/raechel"
                title="Read Raechel's story"
              >
                <StandardButton className="w-100 w-auto-ns" shade="dark">
                  Read Raechel&apos;s story
                </StandardButton>
              </Link>
              <Link
                className="link normal"
                to="/foster-care/can-i-be-a-carer/self-assessment/"
                title="Could you be a foster carer?"
                state={{
                  returnUrl:
                    "/foster-care/types-of-foster-care/respite-foster-care/"
                }}
              >
                <StandardButton className="w-100 w-auto-ns" shade="dark">
                  Could you be a carer?
                </StandardButton>
              </Link>
            </div>
          </div>
        </PromoPanelRightImage>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box>
                <Heading size={2}>Respite foster care facts</Heading>
              </Box>
            </Row>
            <Row>
              <ul className="list flex flex-wrap pl0 mt4">
                <li className="w-50-m w-33-l mt3 mt0-ns ph2 ph3-ns">
                  <StatsCard
                    statisticValue="5300+"
                    explanation="children were reunified with family during 2019–20"
                    theme={fosterCareThemeFull}
                  />
                </li>
                <li className="w-50-m w-33-l mt3 mt0-ns ph2 ph3-ns">
                  <StatsCard
                    statisticValue="174,700"
                    explanation="Australian children received child protection services during 2019-20"
                    theme={fosterCareThemeFull}
                  />
                </li>
                <li className="w-50-m w-33-l mt3 mt0-l ph2 ph3-ns">
                  <StatsCard
                    statisticValue="30,600"
                    explanation="children have been in out-of-home care for 2 years or more"
                    theme={fosterCareThemeFull}
                  />
                </li>
              </ul>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>Your questions answered</Heading>
              <Heading size={3}>What is respite care?</Heading>
              <p>
                As a respite foster carer, you will give children regular and
                occasional time away from their primary foster family or
                parents. The length of care can vary from a day or a weekend to
                a short break over school holidays. “My respite commitment is
                one weekend every six weeks,” says one of our respite carers.
                “The five weeks in between I still do lots of other things. I
                don’t see that respite [care] ever holds me back – if anything
                it enhances [my life].”
              </p>
              <Heading size={3}>
                Do I need special training to become a respite foster carer?
              </Heading>
              <p>
                No, you do not need specific qualifications or training prior to
                applying to be a respite foster carer. Life Without Barriers
                will provide you with specialist training and support throughout
                the onboarding process, so you feel prepared when you begin your
                journey as a carer with us.
              </p>
              <Heading size={3}>
                As a respite foster carer, what kinds of children will be in my
                care?
              </Heading>
              <p>
                Respite care relationships only occurs when it is in the best
                interests of the child. This means it is unlikely you will
                provide care to a baby or young child. One of our respite foster
                carers often takes two girls, aged 12 and eight years, who live
                together with their full-time foster parents. “Although they are
                related, they have very different needs,” the carer said. “It’s
                a fun, interesting and sensitive time in a young woman’s life,
                with plenty of changes. I can remember that time well and how
                important good relationships and strong foundations are.”
              </p>
              <Heading size={3}>Why do children need respite care?</Heading>
              <p>
                Respite foster carers support children and teenagers by
                providing a respectful, supportive relationship, outside of the
                child’s primary carer, in the same way extended family, such as
                aunts, grandparents and family friends, do. Respite foster
                carers also provide invaluable support to a child’s primary
                carers, who may benefit from a short break. Our foster carers
                say they still create strong connections, not only to the
                children in care, but also to their full-time foster carers and
                their own children. “I’ve been made to feel like extended family
                and was invited to spend Christmas with them, which was really
                special for me.”
              </p>
              <Heading size={3}>
                Is it easier to become a respite foster carer than a full-time
                foster carer?
              </Heading>
              <p>
                In a word – no! Although respite foster carers may spend less
                time caring for children than a full-time foster carer, the
                process of becoming a carer is just as rigorous. The type of
                support respite carers can provide is just as important – and
                just as challenging – as full-time foster care, and the team at
                Life Without Barriers want to make sure you are fully prepared.
                Patience is really the key. Some carers undergo 6-12 months of
                onboarding before becoming a respite foster carer. “There is a
                process. You cannot just make a phone call and be registered
                within a week. For some people that can be a deterrent when you
                want to start making a difference quickly. But when you consider
                the sensitivity, the home environment and getting the right
                match… I truly believe it’s worth the wait.” One of our carers
                shared.
              </p>
              <Heading size={3}>
                What kind of support will I get if I become a respite foster
                carer?
              </Heading>
              <p>
                Life Without Barriers works hard to make sure you are never
                alone in your journey as a respite foster carer. We know that to
                be able to provide care and support to the children in your
                care, you need to feel supported and confident in your role. Our
                carers say they have benefited from the support services Life
                Without Barriers provides. “I’ve really enjoyed the training
                sessions that have allowed me to meet other carers and learn
                from their experiences.”
              </p>
              <p>Some support for you as a respite foster carer includes:</p>
              <div className="flex flex-wrap pt3">
                {supportsList.map((li, key) => (
                  <Box key={key} className="w-100 w-50-ns">
                    <Heading size={4} className="color-lwb-theme-dark">
                      {li.header}
                    </Heading>
                    <p>{li.text}</p>
                  </Box>
                ))}
              </div>
              <ReadMoreLink
                className="mt4"
                text="Read more about support for our carers"
                href="/foster-care/carer-support/"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <NavigationTiles
              selectIconFn={iconSelector}
              pages={[ShortAndMedium, EmergencyOrCrisis, AllTypesOfFosterCare]}
              currentPathname={location.pathname}
              heading={<Heading size={2}>Other types of Foster Care</Heading>}
              isLandscape={false}
              isThreeCol
            />
          </Container>
        </Section>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>
                  Got more questions? Get in touch today
                </Heading>
                <div className="mt2 flex flex-row flex-wrap pv3">
                  <ContactUsPhoneLink
                    marginClasses="w-100 w-50-l mv2"
                    phoneNumber="1300 592 227"
                    styles={{ iconBackgroundColor: "#fff" }}
                  />
                  <ContactUsEmailLink
                    marginClasses="w-100 w-50-l mv2"
                    text="carers@lwb.org.au"
                    link="mailto:carers@lwb.org.au"
                    styles={{ iconBackgroundColor: "#FFF" }}
                  />
                </div>
                <p>
                  Our friendly and helpful foster care specialists are ready to
                  answer your questions. Whether you are looking to start the
                  process, or just want more info, our team can help.
                </p>
                <div className="pt3 w-90">
                  You can contact us via social channels, email and telephone.
                  Our team will come back to you as soon as possible.
                </div>
                <div className={"pt4"}>
                  <SocialMediaLinks styles={{ iconBackgroundColor: "#FFF" }} />
                </div>
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <FosterCareForm formId="types-of-care-respite" />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    storyRaechel: file(relativePath: { regex: "/raechel-page.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1200)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default RespiteCarePage
